import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import { motion } from "framer-motion";

// Styled components
const Container = styled.div`
  padding: 20px;
  background-color: #f4f4f4;
  font-size: 14px;
  width: 100%;
`;

const Dropdown = styled.select`
  width: 100%;
  padding: 2vw;
  margin-bottom: 2vw;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-appearance: none; /* Removes default browser styling */
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>'); /* Adds custom dropdown arrow */
  background-repeat: no-repeat;
  background-position: right 1rem center; /* Positions the custom dropdown arrow */
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.timeslot
      ? "repeat(auto-fill, minmax(70px, 1fr));"
      : "repeat(auto-fit, minmax(70px, 1fr))"};
  gap: 5px;
  width: 100%; /* Ensure the grid container takes the full width */

  // max-width: 100%; /* Ensure the grid takes the full width available */
  // border: 1px solid red;
`;

const TimeslotButton = styled.button`
  position: relative;
  width: 100% !important;
  padding: 10px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border: ${(props) =>
    props.selected
      ? "3px solid rgba(255, 133, 89, 1)"
      : "2px solid rgba(0, 0, 0, 0.1)"};
  background-color: ${(props) =>
    props.selected ? "rgba(255, 133, 89, 0.3)" : "#ffffff"};
  color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  cursor: ${(props) => (props.available ? "pointer" : "default")};
  &:hover {
    background-color: ${(props) =>
      props.selected ? "rgba(255, 133, 89, 0.3)" : "#ffffff"};
  }

  &.full {
    background-color: lightgrey;
  }

  &.full::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top right, transparent 50%, grey 50%);
    opacity: 0.5;
    box-sizing: border-box;
  }
`;

const Remark = styled.div`
  font-size: 10px;
  color: red;
`;

const DateAndTimeBooking = ({
  setActiveCourse,
  activeCourse,
  courses,
  program,
  errorFields,
  language = "en",
}) => {
  const [selectedOption1, setSelectedOption1] = useState("");
  const [availableOption2, setAvailableOption2] = useState([]);
  const [noAvailabilityMessage, setNoAvailabilityMessage] = useState("");

  // Function to get first available course
  const getFirstAvailableCourse = useCallback(() => {
    const availableCourse = courses?.find(course => course.availability > 0 && course.open === 1);
    if (availableCourse) {
      setSelectedOption1(availableCourse.option1value);
      setAvailableOption2(courses.filter(
        course => course.option1value === availableCourse.option1value && course.availability > 0 && course.open === 1
      ));
      setActiveCourse(availableCourse);
    }
  }, [courses, setActiveCourse]);

  // Initialize selections from activeCourse or cookie
  useEffect(() => {
    if (courses?.length > 0) {
      let courseToUse = null;

      // First check if we have an activeCourse prop
      if (activeCourse) {
        // Ensure the activeCourse still exists in current courses
        courseToUse = courses.find(c => c.code === activeCourse.code);
      }

      // If no valid activeCourse, check cookie
      if (!courseToUse) {
        const activeCourseFromCookie = Cookies.get('activeCourse');
        if (activeCourseFromCookie) {
          const parsedData = JSON.parse(activeCourseFromCookie);
          const savedCourse = courses.find(c => c.code === parsedData.course.code);
          if (savedCourse && savedCourse.availability > 0) {
            courseToUse = savedCourse;
          }
        }
      }

      // If we have a course to use, set the selections
      if (courseToUse) {
        setSelectedOption1(courseToUse.option1value);
        setAvailableOption2(courses.filter(
          course => course.option1value === courseToUse.option1value && course.availability > 0
        ));
        setActiveCourse(courseToUse);
      } else {
        // Only get first available if we don't have any valid course
        getFirstAvailableCourse();
      }
    }
  }, [courses]); // Remove activeCourse and program?.id from dependencies

  // Handle option1 (date) selection
  const handleOption1Select = (option1) => {
    setSelectedOption1(option1);
    
    // Get all open time slots for the selected date
    const allTimes = courses.filter(
      course => course.option1value === option1 && course.open === 1
    );
    setAvailableOption2(allTimes);
    
    // Clear previous message when selecting new date
    setNoAvailabilityMessage("");
    
    if (allTimes.length === 0) {
      // If no open slots, show message
      setActiveCourse(null);
      Cookies.remove('activeCourse', { path: '/' });
      setNoAvailabilityMessage(
        language === "en" 
          ? "No sessions available for this date. Please select another date."
          : "此日期沒有可用時段，請選擇其他日期。"
      );
    } else {
      // Check if there are any available slots among open timeslots
      const hasAvailableSlots = allTimes.some(time => time.availability > 0);
      
      if (hasAvailableSlots) {
        // If there are available slots, select the first available one
        const firstAvailable = allTimes.find(time => time.availability > 0);
        setActiveCourse(firstAvailable);
      } else {
        // If no available slots, clear the selection and show message
        setActiveCourse(null);
        Cookies.remove('activeCourse', { path: '/' });
        setNoAvailabilityMessage(
          language === "en" 
            ? "All sessions are full for this date. Please select another date."
            : "此日期所有時段已滿，請選擇其他日期。"
        );
      }
    }
  };

  // Function to generate option1 values - only include dates with open timeslots
  const generateOption1Values = useCallback(() => {
    // Get all unique dates
    const allDates = courses?.map((course) => course?.option1value);
    const uniqueDates = Array.from(new Set(allDates));
    
    // Filter dates to only include those with at least one open timeslot
    const datesWithOpenSlots = uniqueDates.filter(date => 
      courses.some(course => 
        course.option1value === date && 
        course.open === 1
      )
    );
    
    return datesWithOpenSlots;
  }, [courses]);

  const option1values = useMemo(() => generateOption1Values(), [generateOption1Values]);

  return (
    <BookingContainer>
      <DateSection>
        <SectionTitle>{program.option1label}</SectionTitle>
        <DateGrid>
          {option1values.map((option1, index) => {
            // Check for both availability and open status
            const hasAvailableCourses = courses.some(
              course => course.option1value === option1 && course.availability > 0 && course.open === 1
            );

            return (
              <DateCard
                key={index}
                $selected={selectedOption1 === option1}
                $hasAvailable={hasAvailableCourses}
                onClick={() => handleOption1Select(option1)}
              >
                <DateValue>{option1}</DateValue>
              </DateCard>
            );
          })}
        </DateGrid>
      </DateSection>

      {selectedOption1 && (
        <TimeSection
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -30 }}
          transition={{ duration: 0.4, ease: "easeOut" }}
        >
          <SectionTitle>{program.option2label}</SectionTitle>
          {noAvailabilityMessage && (
            <WarningMessage>
              {noAvailabilityMessage}
            </WarningMessage>
          )}
          <TimeGrid>
            {courses
              .filter(course => 
                course.option1value === selectedOption1 && 
                course.open === 1 // Only show open timeslots
              )
              .map((time, index) => {
                const isAvailable = time.availability > 0;
                const showRemainingSpots = isAvailable && time.availability < 5;
                
                return (
                  <TimeSlot
                    key={index}
                    $selected={activeCourse?.option2value === time.option2value}
                    $available={isAvailable}
                    onClick={() => isAvailable && setActiveCourse(time)}
                  >
                    <TimeValue $hasStatus={!isAvailable || showRemainingSpots}>
                      {language === "en" ? time.option2value : time.option2value_chi || time.option2value}
                    </TimeValue>
                    {!isAvailable ? (
                      <UnavailableText>
                        {language === "en" ? "Full" : "已滿"}
                      </UnavailableText>
                    ) : showRemainingSpots && (
                      <RemainingSpots>
                        {language === "en" 
                          ? `${time.availability} spots left`
                          : `剩餘 ${time.availability} 個名額`
                        }
                      </RemainingSpots>
                    )}
                  </TimeSlot>
                );
              })}
          </TimeGrid>
        </TimeSection>
      )}

      {courses.filter(course => 
        course.option1value === selectedOption1 && 
        course.open === 1
      ).length === 0 && (
        <AllFullMessage>
          {language === "en" 
            ? "No sessions available for this date" 
            : "此日期沒有可用時段"}
        </AllFullMessage>
      )}

      {!courses.some(course => course.open === 1) && (
        <AllFullMessage>
          {language === "en" 
            ? "No sessions currently available" 
            : "目前沒有可用時段"}
        </AllFullMessage>
      )}
    </BookingContainer>
  );
};

// Modern styled components
const BookingContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 16px;
  }
`;

const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #1a1a1a;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;

  @media (max-width: 768px) {
    font-size: 15px;
    margin-bottom: 8px;
    padding-bottom: 6px;
  }
`;

const DateSection = styled.div`
  width: 100%;
  margin-bottom: 24px;
  background: #f8f9fa;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);

  @media (max-width: 768px) {
    margin-bottom: 16px;
    padding: 12px;
  }
`;

const DateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  gap: 12px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    gap: 8px;
  }
`;

const DateCard = styled.div`
  padding: 12px 10px;
  border-radius: 12px;
  background: ${props => {
    if (!props.$hasAvailable) return '#f5f5f5';
    return props.$selected ? '#e3fcef' : '#ffffff';
  }};
  border: 2px solid ${props => props.$selected ? '#0d9f4f' : '#e6e6e6'};
  cursor: pointer;
  opacity: ${props => props.$hasAvailable ? 1 : 0.8};
  transition: all 0.2s ease;
  font-size: 14px;
  min-height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &:hover {
    transform: ${props => props.$hasAvailable ? 'translateY(-1px)' : 'none'};
    box-shadow: ${props => props.$hasAvailable ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none'};
  }

  @media (max-width: 768px) {
    padding: 8px 6px;
    font-size: 13px;
    border-width: 1px;
    min-height: 70px;
  }
`;

const DateValue = styled.div`
  text-align: center;
  font-weight: 500;
`;

const TimeSection = styled(motion.div)`
  width: 100%;
  margin-top: 24px;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #edf2f7;

  @media (max-width: 768px) {
    margin-top: 16px;
    padding: 16px;
  }
`;

const TimeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  gap: 12px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    gap: 8px;
  }
`;

const TimeSlot = styled.div`
  position: relative;
  padding: 12px 10px;
  border-radius: 12px;
  background: ${props => {
    if (!props.$available) return '#f8f9fa';
    if (props.$selected) return '#e3fcef';
    return '#f8f9fa';
  }};
  border: 2px solid ${props => props.$selected ? '#0d9f4f' : '#e6e6e6'};
  cursor: ${props => props.$available ? 'pointer' : 'not-allowed'};
  transition: all 0.3s ease;
  opacity: ${props => props.$available ? 1 : 0.9};
  font-size: 14px;
  overflow: hidden;
  min-height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  &:hover {
    transform: ${props => props.$available ? 'translateY(-1px)' : 'none'};
    box-shadow: ${props => props.$available ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none'};
  }

  /* Diagonal line for unavailable slots */
  ${props => !props.$available && `
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 141.4%;
      height: 1.5px;
      background: rgba(128, 128, 128, 0.5);
      transform: rotate(-45deg) translateX(-20.7%);
      transform-origin: center;
      pointer-events: none;
      z-index: 1;
    }
  `}

  @media (max-width: 768px) {
    padding: 8px 6px;
    font-size: 13px;
    border-width: 1px;
    min-height: 70px;
  }
`;

const TimeValue = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  margin-bottom: ${props => props.$hasStatus ? '20px' : '0'};
`;

const AllFullMessage = styled.div`
  text-align: center;
  padding: 12px;
  margin-top: 16px;
  background: #fff3e0;
  border-radius: 8px;
  color: #f57c00;
  font-weight: 500;
  font-size: 14px;
  border: 1px dashed #ffa726;

  @media (max-width: 768px) {
    padding: 10px;
    margin-top: 12px;
    font-size: 13px;
  }
`;

const UnavailableText = styled.div`
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #999;
  font-weight: 500;
`;

const RemainingSpots = styled.div`
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #ff6b6b;
  font-weight: 500;
`;

const WarningMessage = styled.div`
  text-align: center;
  padding: 8px 12px;
  margin-bottom: 12px;
  background: #fff3e0;
  border-radius: 8px;
  color: #f57c00;
  font-size: 13px;
  border: 1px dashed #ffa726;
`;

export default DateAndTimeBooking;