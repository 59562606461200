import React, { useState, useEffect, useRef } from "react"; // Import React and hooks
import styled from "styled-components"; // Import styled-components for styling
import { motion } from "framer-motion"; // Import framer-motion for animations
import AgreementText from "./AgreementText"; // Import AgreementText component

// Styled button using framer-motion for animation
const CenteredButton = styled(motion.button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 100px;
  font-size: 16px;
  cursor: pointer;
`;

// Styled modal content
const Container = styled.div`
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  position: relative;

  @media (max-width: 280px) {
    width: 95%;
  }

  p {
    line-height: 1.6;
  }
`;

// Styled scroll indicator
const ScrollIndicator = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 8px;
  // background: red;
  transition: height 0.1s ease-in-out;
  // border: 10px solid red;
`;

// Styled checkbox container
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  border: 1px solid black;
  padding: 10px;
`;

// Styled label for better accessibility and design
const Label = styled.label`
  margin-left: 15px;
  font-size: 14px;
`;

// Styled input for the checkbox
const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
  text-align: left;
`;

// Scroll down indicator using framer-motion for animation
const ScrollDownIndicator = styled(motion.div)`
  position: absolute;
  bottom: 10px;
  left: 0;
  font-size: 14px;
  color: white;
  display: ${(props) => (props.visible ? "flex" : "none")};
  justify-content: center;
  width: 100%;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const DisclaimerConsentModal = ({
  setModalCheckbox,
  ModalCheckbox,
  formData,
  setFormData,
  setModalOpen,
  content,
  language,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(true); // State to control modal visibility
  const [isChecked, setIsChecked] = useState(false); // State to control checkbox status
  const [isBottom, setIsBottom] = useState(false); // State to check if user has scrolled to the bottom
  const [showScrollReminder, setShowScrollReminder] = useState(false); // State to show scroll reminder
  const contentRef = useRef(null); // Ref for modal content
  const scrollIndicatorRef = useRef(null); // Ref for scroll indicator

  // Determine button text and animation based on checkbox status
  const buttonText = isChecked ? "Confirmed ✅" : "Disclaimer";
  const buttonAnimation = isChecked
    ? { scale: 1, transition: { duration: 0 } }
    : { scale: [1, 1.12, 1], transition: { repeat: Infinity, duration: 1 } };

  // Function to handle checkbox toggle
  const toggleCheckbox = (event) => {
    setIsChecked(!isChecked);
    const { name, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
    if (checked) {
      setModalOpen(!checked);
    }
  };

  // Function to handle scroll event
  const handleScroll = () => {
    console.log("scrollIndicatorRef.current");
    if (contentRef.current && scrollIndicatorRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      console.log(contentRef.current);
      setIsBottom(scrollTop + clientHeight >= scrollHeight); // Check if scrolled to bottom
      const scrollHeightPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;
      scrollIndicatorRef.current.style.height = `${scrollHeightPercentage}%`; // Update scroll indicator height
      setShowScrollReminder(false); // Hide scroll reminder on scroll
    }
  };

  // Effect to add scroll event listener
  useEffect(() => {
    const content = contentRef.current;
    if (content) {
      const { scrollHeight, clientHeight } = content;
      if (scrollHeight > clientHeight) {
        content.addEventListener("scroll", handleScroll);
      } else {
        console.log("no need to scroll");
        setIsBottom(true);
      }
    }

    return () => {
      if (content) {
        content.removeEventListener("scroll", handleScroll);
      }
    };
  }, [contentRef.current]);

  // Effect to show scroll reminder after 3 seconds of inactivity
  useEffect(() => {
    if (!isBottom) {
      const timer = setTimeout(() => {
        setShowScrollReminder(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isBottom]);

  console.log(content)

  return (
    <>
      {/* {/* <CenteredButton
        onClick={() => setIsModalVisible(true)}
        animate={buttonAnimation}
      >
        {buttonText}
      </CenteredButton> */}
      <Container ref={contentRef}>
        <ScrollIndicator ref={scrollIndicatorRef} />
        <ScrollDownIndicator
          visible={!isBottom && showScrollReminder}
          animate={{ y: [0, -10, 0] }}
          transition={{ repeat: Infinity, duration: 1 }}
        >
          Scroll down! ⬇
        </ScrollDownIndicator>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        {/* <AgreementText /> */}
        <CheckboxContainer visible={isBottom}>
          <Checkbox
            type="checkbox"
            id="disclaimerConsent"
            name="disclaimerConsent"
            onChange={toggleCheckbox}
            checked={formData.disclaimerConsent}
          />

          {language == "en" ? (
            <Label htmlFor="disclaimerConsent">
              I agree to the terms and conditions.
            </Label>
          ) : (
            <Label htmlFor="disclaimerConsent"> 我同意條款和條件。</Label>
          )}
        </CheckboxContainer>
      </Container>
    </>
  );
};

export default DisclaimerConsentModal;
