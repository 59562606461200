import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import styled from "styled-components";
import QRCode from 'qrcode.react';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import AbsentIcon from "./AbsentIcon"; // Adjust the path as necessary
import AttendedIcon from "./AttendedIcon"; // Adjust the path as necessary
import ExcusedIcon from "./ExcusedIcon"; // Adjust the path as necessary

const BackIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;
const Glyph = styled.div`
  width: 40px;
  height: 40px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ScreenTitle = styled.i`
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-size:20px;
  font-weight:700;
`;

const ScreenSubtitle = styled.div`
  width: 247px;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 14px;
  color: #ffffff;
  display: block;
   font-size:20px;
  font-weight:700;
`;
const TitleWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  gap: var(--gap-5xs);
`;
const Glyph1 = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Topbar = styled.div`
  align-self: stretch;
  background-color: var(--color-steelblue-100);
  height:70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  box-sizing: border-box;
  gap:5px;
  // border:1px solid red;
`;

// const Fixed = styled.div`
//   position: relative;
//   top: 44px;
//   left: calc(50% - 187.5px);
//   backdrop-filter: var(--blur-glass);
//   background-color: rgba(51, 75, 123, 0.7);
//   width: 375px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-start;
// `;

const CodeIcon = styled.img`
  width: 240px;
  position: relative;
  height: 240px;
  object-fit: cover;
`;
const CodeWrapper = styled.div`
  box-shadow: 0px 4px 32px rgba(255, 255, 255, 0.25);
  border-radius: var(--br-base);
  background-color: var(--text-main);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs);
`;
const Description = styled.div`
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
 	color: orange;
  border:1px solid orange;
  border-radius: 5px;
  width:100%;
  text-align:left;
  padding:10px;
`;
const Qr = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base);
  gap: var(--gap-base);
`;
const TearLineChild = styled.img`
  width: 20px;
  position: relative;
  height: 40px;
`;
const TearLineItem = styled.div`
  width: 12px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gray-100);
  height: 12px;
`;
const EllipseIcon = styled.img`
  width: 20px;
  position: relative;
  height: 40px;
  object-fit: contain;
`;
const TearLine = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const A069IndividualPrivate = styled.i`
  flex: 1;
  position: relative;
`;

const Infoheader = styled.i`
  flex: 1;
  position: relative;
  font-size: 20px;
  font-weight: 700;
`;

const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Venue = styled.div`
  width: 100px;
  position: relative;
  line-height: 18px;
  display: inline-block;
  flex-shrink: 0;
`;
const PrivateRink = styled.i`
  align-self: stretch;
  position: relative;
`;
const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--text-main);
`;
const Item = styled.div`
  width: 343px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Monday = styled.i`
  flex: 1;
  position: relative;
  line-height: 18px;
  color: var(--text-main);
`;
const Pm = styled.div`
  position: relative;
  line-height: 18px;
  color: var(--text-main);
`;
const BenMacaskill = styled.i`
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 18px;
  color: var(--text-main);
`;
const Details = styled.div`
  align-self: stretch;
  height: 114px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-lightsteelblue);
`;
const Info = styled.div`
  align-self: stretch;
  // height: 163px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-base);
  text-align: left;
  font-family: var(--font-abeezee);
`;
const Ticket = styled.div`
  position: relative;
  // top: 108px;
  margin-bottom: 44px;
  left: calc(50% - 188px);
  border-radius: 0px 0px var(--br-base) var(--br-base);
  background-color: var(--color-steelblue-100);
  width: 376px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-sm);
  font-family: var(--font-ibm-plex-sans);
`;
const QrCodeRoot = styled.div`
  // border: solid green 3px;

  width: 100%;
  position: relative;
  background-color: #c4edff;
  // height: 802px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--text-main);
  font-family: var(--font-abeezee);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  width:100%;
    color:black;
`;

export const Header = styled.div`
  font-weight: bold;
  text-align: left;
  flex: 1;
`;

export const Cell = styled.div`
  text-align: left;
  flex: 1;

`;

export const ThickLine = styled.div`
  height: 1.5px;
  background-color: black;
  margin-bottom: 10px;
`;

export const NameTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-left: 10px solid rgb(0, 0, 139);
  width: 100%;
  color: black;
  padding-left: 20px;
`;

export const ClassInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  width:100%;
  padding:10px 10px;
  // border-radius: 0 0 20px 20px;
  border-bottom:15px solid lightgrey;
`;

export const Subheader = styled.div`
  font-weight: 700;
  font-size: 20px;
  // text-align: left; // Center text
  margin-top: 10px; // Adjust spacing after venue
  margin-bottom:20px;
  // display:flex;
  // align-items:center;
  // justify-content:flex-start;
  border-left:10px solid rgb(0,0,139);
  width:100%;
  color:black;
  padding-left:20px;
  
`;

export const ClassTitle = styled.div`
  width: 95%;
  height: auto;
  font: 700 20px Poppins, sans-serif;
  text-align: center;
  margin-bottom: 10px;
  color:black;
  // border-bottom: 2px solid green;
`;

const QrCodeTicket = ({orderInfo, language}) => {
  const orderDetails = orderInfo.order_details;
  const participantDetails = orderInfo.participant_info;
  const contact = orderInfo.contact;
  const email = orderInfo.email;
  const orderToken = orderInfo.token;
  const status = orderInfo.status;

  const remarks = {
    en: [
      "Please arrive 10 mins before the start time of your session. If late for 10 mins, you will be treated as no show and your registration will be released to the other participant.",
      "Please wear long-sleeved shirts, long pants, thick long socks and shoes (no sandals/slippers) as possible.",
      "Guests must wear helmet and gloves on the skating rink. Please bring your own helmet. If you don't have them, the company will lend out shared helmets. We will also provide skates and gloves for Ice Hockey & Skating Experience participants."
    ],
    ch: [
      "參加者須於10分鐘前到達會場登記，遲到者將被視為自動棄權。本會保留權利安排候補人士取代進場",
      "請盡量穿長袖襯衫、長褲、長襪和鞋子（不要穿涼鞋/拖鞋）",
      "客人必須在仿真冰場戴上頭盔及手套。主辦單位會提供溜冰鞋，手套及頭盔給冰球及溜冰體驗的參加者，但建議參加者自行攜帶頭盔以確保更舒適的配戴。"
    ]
  };

  return (
    <TicketContainer>
      <TicketHeader>
        <HeaderContent>
          <OrderId>
            {language === "en" ? "Order ID: " : "訂單編號: "}
            {orderInfo?.formatted_order_id}
          </OrderId>
          <OrderDate>
            {language === "en" ? "Order Date: " : "訂單日期: "}
            {new Date(orderInfo?.order_date).toLocaleString(undefined, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            })}
          </OrderDate>
          <StatusBadge $status={status}>
          {status === 1 ? (
  language === "en" ? "Confirmed" : "已確認"
) : status === 9 ? (
  language === "en" ? "Cancelled" : "已取消"
) : (
  language === "en" ? "Pending" : "等待確認"
)}
          </StatusBadge>
        </HeaderContent>
      </TicketHeader>

      <TicketBody>
        {status === 1 ? (
          <QrSection>
            <QrWrapper>
              <QRCode 
                value={`https://booking.dynevents.com/admin/checkin/?token=${orderToken}`}
                size={200}
                level="H"
              />
            </QrWrapper>
            <QrInstructions>
              {language === "en" ? (
                "Scan QR code at venue entrance"
              ) : (
                "請在場地入口掃描二維碼"
              )}
            </QrInstructions>
            <ReminderText>
              {language === "en" ? (
                "You will receive an SMS reminder one day before the event."
              ) : (
                "活動前一天您將收到簡訊提醒。"
              )}
            </ReminderText>
          </QrSection>
        ) : status === 9 ? (
  <PendingSection>
    <PendingText>
      {language === "en"
        ? "Your order has been cancelled."
        : "您的訂單已被取消。"}
    </PendingText>
  </PendingSection>
) : (
  <PendingSection>
    <PendingText>
      {language === "en"
        ? "Your booking is being processed. You will receive an E-Ticket once confirmed."
        : "您的預約正在處理中。確認後您將收到電子門票。"}
    </PendingText>
  </PendingSection>
)}
        <RemarksSection>
          <RemarksTitle>
            {language === "en" ? "Remarks:" : "注意事項:"}
          </RemarksTitle>
          <RemarksList>
            {remarks[language === "en" ? "en" : "ch"].map((remark, index) => (
              <RemarkItem key={index}>
                {index + 1}. {remark}
              </RemarkItem>
            ))}
          </RemarksList>
        </RemarksSection>

        <Divider />

        <InfoSection>
          <SectionTitle>
            {language === "en" ? "Event Details" : "活動詳情"}
          </SectionTitle>
          <InfoGrid>
            <InfoItem>
              <InfoLabel>{language === "en" ? "Program" : "活動名稱"}</InfoLabel>
              <InfoValue>
                {language === "en" ? orderDetails?.programName : orderDetails?.programNameChi}
              </InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>{language === "en" ? "Package" : "套餐"}</InfoLabel>
              <InfoValue>
                {orderDetails?.option1value_chi || orderDetails?.option1value}
              </InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>{language === "en" ? "Options" : "選項"}</InfoLabel>
              <InfoValue>
                {orderDetails?.option2value_chi || orderDetails?.option2value}
              </InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>{language === "en" ? "Quantity" : "人數"}</InfoLabel>
              <InfoValue>
                {orderDetails?.quantity || "1"}
              </InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>{language === "en" ? "Total Price" : "總價"}</InfoLabel>
              <InfoValue>
                {orderDetails?.fee === "0" 
                  ? (language === "en" ? "Free" : "免費") 
                  : `HK$ ${orderDetails?.totalPrice || orderDetails?.fee}`
                }
              </InfoValue>
            </InfoItem>
            {orderDetails?.selectedDates && (
              <InfoItem $fullWidth>
                <InfoLabel>{language === "en" ? "Dates" : "日期"}</InfoLabel>
                <DateList>
                  {orderDetails.selectedDates.map((classDate, index) => (
                    <DateItem key={index}>
                      {new Date(classDate.date).toLocaleDateString()} - {classDate.name}
                    </DateItem>
                  ))}
                </DateList>
              </InfoItem>
            )}
          </InfoGrid>
        </InfoSection>

        <Divider />

        <InfoSection>
          <SectionTitle>
            {language === "en" ? "Participant Details" : "參加者資料"}
          </SectionTitle>
          <InfoGrid>
            <InfoItem>
              <InfoLabel>{language === "en" ? "Last Name" : "姓氏"}</InfoLabel>
              <InfoValue>{participantDetails?.lastname}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>{language === "en" ? "First Name" : "名字"}</InfoLabel>
              <InfoValue>{participantDetails?.firstname}</InfoValue>
            </InfoItem>
          </InfoGrid>
        </InfoSection>

        <Divider />

        <InfoSection>
          <SectionTitle>
            {language === "en" ? "Contact Information" : "聯絡資料"}
          </SectionTitle>
          <InfoGrid>
            <InfoItem>
              <InfoLabel>{language === "en" ? "Mobile" : "電話號碼"}</InfoLabel>
              <InfoValue>{contact}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>{language === "en" ? "Email" : "電郵"}</InfoLabel>
              <InfoValue>{email}</InfoValue>
            </InfoItem>
          </InfoGrid>
        </InfoSection>
      </TicketBody>
    </TicketContainer>
  );
};

// Modern styled components
const TicketContainer = styled.div`
  max-width: 600px;
  margin: 20px auto;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  overflow: hidden;
`;

const TicketHeader = styled.div`
  background: white;
  padding: 24px;
  border-bottom: 1px solid #eee;
`;

const HeaderContent = styled.div`
  text-align: center;
`;

const OrderId = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
`;

const OrderDate = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;
`;

const StatusBadge = styled.div`
  display: inline-block;
  padding: 8px 16px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  background: ${props => props.$status === 1 ? '#e3fcef' : '#fff3e0'};
  color: ${props => props.$status === 1 ? '#0d9f4f' : '#f57c00'};
`;

const TicketBody = styled.div`
  padding: 24px;
`;

const QrSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
`;

const QrWrapper = styled.div`
  padding: 16px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
`;

const QrInstructions = styled.p`
  margin-top: 16px;
  color: #666;
  font-size: 14px;
`;

const ReminderText = styled.div`
  margin-top: 16px;
  padding: 12px;
  background: #e3fcef;
  border-radius: 8px;
  color: #0d9f4f;
  font-size: 14px;
  text-align: center;
  line-height: 1.5;
  max-width: 80%;
`;

const PendingSection = styled.div`
  text-align: center;
  padding: 24px;
  background: #fff3e0;
  border-radius: 12px;
  margin-bottom: 32px;
`;

const PendingText = styled.div`
  color: #666;
  font-size: 16px;
  line-height: 1.5;
`;

const RemarksSection = styled.div`
  margin-top: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
`;

const RemarksTitle = styled.div`
  font-weight: 600;
  margin-bottom: 10px;
  color: #1a1a1a;
`;

const RemarksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RemarkItem = styled.div`
  font-size: 13px;
  line-height: 1.5;
  color: #4a4a4a;
`;

const Divider = styled.div`
  height: 1px;
  background: #eee;
  margin: 24px 0;
`;

const InfoSection = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
  padding-left: 12px;
  border-left: 4px solid #0c729e;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const InfoItem = styled.div`
  padding: 12px;
  background: #f8f9fa;
  border-radius: 8px;
  grid-column: ${props => props.$fullWidth ? '1 / -1' : 'auto'};
`;

const InfoLabel = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: 500;
`;

const DateList = styled.div`
  margin-top: 8px;
`;

const DateItem = styled.div`
  padding: 8px 0;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #eee;
  
  &:last-child {
    border-bottom: none;
  }
`;

export default QrCodeTicket;
