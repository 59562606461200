//ClassDetails.js
import React, { useEffect, useState, useContext, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion"; //CHANGES by Rick:
// import { CSSTransition } from "react-transition-group"; //CHANGES by Rick:
import styled from "styled-components";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import ButtonMobile from "../../components/ButtonMobile/ButtonMobile"; //Join the class button
import ButtonBack from "../../components/ButtonMobile/ButtonBack"; //Circle Back Button
import LessonSelector from "../../components/SelectClassButton/SelectClassButton"; //Select Class Buttons
import { LocationPin } from "../../assets/IconSVG.js";
import DateAndTimeBooking from "../../components/Form/DateAndTimeBooking"; //Select
import Cookies from "js-cookie";
import api from "../../Api"; // adjust the path based on where api.js is located
import { HistoryContext } from "../../context/historyContext";
import TopSlider from "../../components/PhotoSlider/TopSlider";
import { createGlobalStyle } from "styled-components";
import MessageModal from "../../components/Modal/Modal";
import QuantityDateSelector from "../../components/Modal/QuantityDateSelector";

export const ClassCard = ({ classInfo, language = "en" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeCourse, setActiveCourse] = useState(null);
  const [activeProgram, setActiveProgram] = useState(null);
  const [price, setPrice] = useState(0);
  const [order, setOrder] = useState({
    course_id: "",
    price: "",
    name: "",
    option1Value: "",
    option2Value: "",
    fee: "",
  });

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const handleDateTimeChange = (newDate, newTime) => {
    setDate(newDate);
    setTime(newTime);
  };

  const [errorFields, setErrorFields] = useState({});

  const validateOrder = (order) => {
    let errors = {};

    for (let key in order) {
      if (order[key] === "") {
        errors[key] = true;
      }
    }
    setErrorFields(errors);
    return Object.keys(errors).length === 0; // Valid form if no errors/ return true or false
  };

  const setCookie = (name, value) => {
    Cookies.set(name, value, {
      expires: 1 / 96, // Cookie expires in 15 mins
      path: "/", // Cookie accessible for the whole site
      secure: process.env.REACT_APP_NODE_ENV === "production", // Only transmit cookie over HTTPS if in production
      sameSite: "strict", // Strictly same site; adjust as needed
    });
  };

  const handleSubmit = (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
      e.stopPropagation();
    }

    const activeCourseFromCookie = Cookies.get("activeCourse");
    if (activeCourseFromCookie) {
      const parsedData = JSON.parse(activeCourseFromCookie);
      const activeCourse = parsedData.course;

      if (activeCourse.requireSelection) {
        setTimeout(() => {
          setModalOpen(true);
          setModalTemplate("quantityDateSelector");
        }, 100);
      } else {
        const selectedClassIds = activeCourse.classes?.map((classItem) => classItem.id) || [];
        const fee = Number(activeCourse.fee) || 0;
        const totalPrice = 1 * fee;

        Cookies.set(
          "activeCourse",
          JSON.stringify({
            course: activeCourse,
            program: parsedData.program,
            quantity: 1,
            selectedClassIds: selectedClassIds,
            totalPrice: totalPrice,
          })
        );

        const isValid = validateOrder(parsedData.course);
        if (isValid) {
          navigate(`/${language}/classconfirmpayment`);
        }
      }
    }
  };

  const handleModalConfirm = (data) => {
    console.log("Confirmed data:", data);
    setModalOpen(false);

    const activeCourseFromCookie = Cookies.get("activeCourse");
    if (activeCourseFromCookie) {
      const parsedData = JSON.parse(activeCourseFromCookie);
      const activeCourse = parsedData.course;

      // If requireSelection is false, use all class IDs
      const selectedClassIds = !activeCourse.requireSelection
        ? activeCourse.classes?.map((classItem) => classItem.id) || []
        : data.selectedDates?.map((date) => date.id) || [];

      const fee = Number(activeCourse.fee) || 0;
      const totalPrice = data.quantity * fee;

      // Make sure we're setting selectedClassIds at the top level here too
      Cookies.set(
        "activeCourse",
        JSON.stringify({
          course: activeCourse,
          program: parsedData.program,
          quantity: data.quantity,
          selectedClassIds: selectedClassIds, // Set at top level
          totalPrice: totalPrice,
        })
      );

      const isValid = validateOrder(parsedData.course);
      if (isValid) {
        navigate(`/${language}/classconfirmpayment`);
      }
    }
  };

  const handleModalCancel = () => {
    setModalOpen(false);
  };

  const { history, setHistory } = useContext(HistoryContext);
  const [slideDirection, setSlideDirection] = useState("right");
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTemplate, setModalTemplate] = useState(null);
  const [autoClose, setAutoClose] = useState(false);
  const [isQuantityDateSelectorVisible, setQuantityDateSelectorVisible] =
    useState(false);

  const stickyRef = useRef(null);

  useEffect(() => {
    localStorage.setItem("history", JSON.stringify(history));
  }, [history]);

  useEffect(() => {
    setHistory(location.pathname + location.search);

    if (classInfo?.courses?.length > 0) {
      const activeCourseFromCookie = Cookies.get("activeCourse");

      if (activeCourseFromCookie) {
        const parsedData = JSON.parse(activeCourseFromCookie);
        // Check if the saved course belongs to current program
        if (parsedData.program.id === classInfo.id) {
          // Find the course in current courses array to ensure it's fresh data
          const currentCourse = classInfo.courses.find(
            (course) => course.code === parsedData.course.code
          );
          if (currentCourse && currentCourse.availability > 0) {
            setActiveCourse(currentCourse);
            setActiveProgram(parsedData.program);
          }
        }
      }
    }
  }, [classInfo]); // Remove location.pathname from dependencies

  useEffect(() => {
    if (activeCourse && classInfo?.code) {
      setActiveProgram(classInfo.code);
      Cookies.set(
        "activeCourse",
        JSON.stringify({
          course: activeCourse,
          program: classInfo.code,
        }),
        {
          expires: 1 / 96, // 15 minutes
          path: "/",
        }
      );
    }
  }, [activeCourse, classInfo?.code]);

  useEffect(() => {
    const updateStickyHeight = () => {
      if (stickyRef.current) {
        const height = stickyRef.current.offsetHeight;
        document.documentElement.style.setProperty(
          "--sticky-height",
          `${height}px`
        );
      }
    };

    updateStickyHeight();
    window.addEventListener("resize", updateStickyHeight);

    return () => window.removeEventListener("resize", updateStickyHeight);
  }, []);

  const queryParams = new URLSearchParams(location.search);
  const single = queryParams.get("single") === "true";

  const handleOptionClick = (option) => {
    if (activeCourse && activeCourse.code !== option.code) {
      setSlideDirection(activeCourse.code < option.code ? "right" : "left");
    }
  };

  return (
    <>
      <CardContent>
        {!single && (
          <ButtonWrapper>
            <ButtonBack onClick={() => navigate(-1)} />
          </ButtonWrapper>
        )}

        {classInfo?.images && <TopSlider images={classInfo?.images} />}
        <CardContentWrapper>
          <HeaderSection>
            <CategoryBadge>{classInfo.category}</CategoryBadge>
            <ProgramTitle>{classInfo.name}</ProgramTitle>
            <ProgramDate>{classInfo.date}</ProgramDate>
            <LocationButton
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    classInfo.venue
                  )}`,
                  "_blank"
                )
              }
            >
              <LocationIcon>
                <LocationPin />
              </LocationIcon>
              <LocationText>{classInfo.venue}</LocationText>
              <MapLink>Open in Maps</MapLink>
            </LocationButton>
          </HeaderSection>

          {/* ***********************************
                SELECT AND CONTENT AREA
          *********************************** */}
          {classInfo?.useTimeslotMode ? (
            <ContentLayout>
              <Subheader style={{ marginTop: 0 }}>
                {language === "en" ? "Select your package" : "選擇課程"}
              </Subheader>
              <StickyWrapper>
                <StickySection>
                  <div className="full-booking">
                    <DateAndTimeBooking
                      courses={classInfo?.courses}
                      program={classInfo}
                      setActiveCourse={setActiveCourse}
                      activeCourse={activeCourse}
                      errorFields={errorFields}
                      language={language}
                    />
                  </div>
                </StickySection>
              </StickyWrapper>
              {/* <Subheader>Details</Subheader>

              <ScrollableSection>
                {activeCourse && (
                  <AnimatePresence mode="wait">
                    {classInfo?.courses.map(
                      (option, index) =>
                        activeCourse?.code === option.code && (
                          <motion.div
                            key={index}
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 50 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                          >
                            <CourseDetailsCard>
                              <CourseContent
                                dangerouslySetInnerHTML={{
                                  __html: wrapContentInDivs(option?.details),
                                }}
                              />
                            </CourseDetailsCard>
                          </motion.div>
                        )
                    )}
                  </AnimatePresence>
                )}
              </ScrollableSection> */}

              <Subheader style={{ marginTop: "16px" }}>
                {language === "en" ? "What to expect" : "課程內容"}
              </Subheader>
              <ClassDescription>
                <div
                  dangerouslySetInnerHTML={{ 
                    __html: language === "en" 
                      ? classInfo?.description 
                      : classInfo?.description_chi || classInfo?.description 
                  }}
                />
              </ClassDescription>
            </ContentLayout>
          ) : (
            <>
              <Subheader>
                {language === "en" ? "Course options" : "課程選項"}
              </Subheader>
              <LessonSelector
                courses={classInfo?.courses}
                program={classInfo}
                setActiveCourse={setActiveCourse}
                activeCourse={activeCourse}
                errorFields={errorFields}
                handleOptionClick={handleOptionClick}
                language={language}
              />
            </>
          )}

          {/* Footer Section */}
          <JoinClassFooterWrapper>
            {!activeCourse ? (
              classInfo?.courses?.some((course) => course.availability > 0) ? (
                <OptionListPrice>
                  {language === "en" ? "Please select" : "請選擇"}
                </OptionListPrice>
              ) : (
                <OptionListPrice $full>
                  {language === "en" ? "All sessions are full" : "所有課程已滿"}
                </OptionListPrice>
              )
            ) : activeCourse?.fee === "0" ? (
              <OptionListPrice>
                {language === "en" 
                  ? activeCourse?.option1value 
                  : activeCourse?.option1value_chi || activeCourse?.option1value}
                <br />
                {language === "en"
                  ? activeCourse?.option2value
                  : activeCourse?.option2value_chi || activeCourse?.option2value}
              </OptionListPrice>
            ) : (
              <OptionListPrice>HK$ {activeCourse?.fee}</OptionListPrice>
            )}

            <ButtonMobile
              onClick={() => handleSubmit()}
              disabled={!activeCourse}
            >
              {classInfo?.courses?.some((course) => course.availability > 0)
                ? language === "en" ? "BOOK" : "預訂"
                : language === "en" ? "FULL" : "已滿"}
            </ButtonMobile>
          </JoinClassFooterWrapper>

          {/* Modal */}
          {isModalOpen && (
            <MessageModal
              isOpen={isModalOpen}
              setModalOpen={setModalOpen}
              template={modalTemplate}
              onConfirm={handleModalConfirm}
              onCancel={handleModalCancel}
              baseFee={activeCourse?.fee || 0}
              classDates={activeCourse?.classes || []}
              maxQuantity={activeCourse?.capacity || 0}
              language={language}
            />
          )}

          {/* Footer */}
          <FooterWrapper>
            <div>
              Powered by <img src="/dynamic_logo.svg" alt="Dynevents Logo" /> |
              booking.dynevents.com
            </div>
          </FooterWrapper>
        </CardContentWrapper>
      </CardContent>
    </>
  );
};

export const ClassDetails = () => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const { program_code } = useParams();

  const host = window.location.hostname;
  const subdomain = host.split(".")[0];
  const shopName = subdomain;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/classBooking/api/class/${program_code}`);

        // let uniqueArray = [...new Set(res.data.map((item) => item.category))]; // Remove duplicates

        // let newtabValues = [
        // 	{ name: "tab_1", label: "All Events" },
        // 	...uniqueArray.map((item, index) => ({
        // 		name: `tab_${index + 2}`, // Start from tab_2
        // 		label: item,
        // 	})),
        // ];
        setData(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
    // setResultChange("All");

    // const intervalId = setInterval(fetchData, 1000);

    // Clean up the interval when the component unmounts or when dependencies change
    // return () => {
    // 	clearInterval(intervalId);
    // };
  }, []);

  return (
    <>
      <ClassCard classInfo={data} />
    </>
  );
};
export default ClassDetails;

//STYLED-COMPONENTS:
export const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // padding-bottom: 60px;
  color: black;
  // border:1px solid red;
  // height:100vh;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 10;
`;

export const ClassImage = styled.img`
  object-fit: auto;
  object-position: center;
  width: 100%;
`;

export const CardContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;
  position: relative;
`;

export const ClassTitle = styled.div`
  font: 700 28px Poppins, sans-serif;
  text-align: left; // Center text
  margin-top: 10px;
  // margin-bottom: 10px; // Adjust spacing after title
`;

export const Label = styled.div`
  font: 15px Lora, sans-serif;
  text-align: center; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  background-color: lightgrey;
  color: black;
  border-radius: 2px;
  padding: 2px 5px;
`;

export const DetailsContainer = styled.div`
  font: 15px Lora, sans-serif;
  text-align: left; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  background-color: rgba(227, 188, 154, 10);
  color: black;
  border-radius: 2px;
  padding: 2px 5px;
`;

export const ClassVenue = styled.div`
  font: italic 700 20px Lora, sans-serif;
  // text-align: left; // Center text
  margin-top: 10px; // Adjust spacing after venue
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // border:1px solid red;
  width: 100%;
  color: grey;

  svg {
    width: 20px;
    margin-right: 10px;
    fill: grey;
  }
`;

export const Subheader = styled.h2`
  font-size: 22px;
  font-weight: 700;
  color: #1a1a1a;
  margin: 16px 0 12px;
  padding-left: 16px;
  border-left: 4px solid #0d9f4f;
  line-height: 1.3;

  &:first-of-type {
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin: 14px 0 10px;
    padding-left: 12px;
  }
`;

export const ClassDetailsStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr; // Defines two columns: 1st column is 1/4th, 2nd column is 3/4ths of the total width
  gap: 10px; // Gap between grid items
  width: 85%; //Makes the grid 80% of the width of the parent container
  padding: 10px 0px;
`;

export const DetailItem = styled.div`
  display: contents; // Makes the container behave like a direct child of the grid container
`;

export const DetailLabel = styled.span`
  color: var(--Text-Muted, gray);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

export const DetailValue = styled.span`
  font-family: Lora, sans-serif;
  font-weight: 500;
  font-size: 14px;
`;

export const TransportationNote = styled.p`
  font: italic 700 14px Lora, sans-serif;
  align-self: center;
  margin-top: 10px;
  color: #ff4f4f;
  text-align: center;
`;

const ClassDescription = styled.div`
  width: 100%;
  background: #f1f8f3;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 4px 16px rgba(13, 159, 79, 0.12);
  color: #2c3e50;
  font-size: 15px;
  line-height: 1.6;
  position: relative;
  margin-top: -4px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to bottom right,
      rgba(13, 143, 71, 0.05),
      rgba(13, 143, 71, 0.02)
    );
    border-radius: 16px;
    pointer-events: none;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    color: #2c3e50;
    margin: 20px 0 10px;
    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin: 0 0 12px;
    color: #34495e;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid rgba(13, 143, 71, 0.1);
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    margin: 0 0 16px 0;
    padding-left: 24px;

    li {
      margin-bottom: 8px;
      padding: 8px 12px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid rgba(13, 143, 71, 0.1);
    }
  }

  @media (max-width: 768px) {
    padding: 16px;
    font-size: 14px;

    h2 {
      font-size: 15px;
      margin: 20px 0 10px;
    }

    p,
    li {
      padding: 10px;
    }
  }
`;

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  font-size: 12px;
  // border-radius: 12px 12px 0 0;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  z-index: 1000;
  @media (min-width: 500px) {
    width: 500px; /* Apply max-width constraint */
    left: calc((100vw - 500px) / 2);
    right: calc((100vw - 500px) / 2);
  }

  img {
    display: inline-block;
    width: 20px;
  }
`;

const JoinClassFooterWrapper = styled.div`
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  padding: 10px 10px;
  // border:1px solid red;
  border-radius: 12px 12px 0 0;
  background-color: var(--color-white);
  z-index: 1000;
  width: 100%;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); // Very thin drop shadow
  @media (min-width: 500px) {
    width: 500px; /* Apply max-width constraint */
  }
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  // gap: 20px;

  div {
    flex: 2;
  }

  button {
    flex: 1;
  }
`;

const BookingFooterWrapper = styled.div`
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  padding: 10px 10px;
  // border:1px solid red;
  border-radius: 12px 12px 0 0;
  background-color: var(--color-white);
  z-index: 1000;
  width: 100%;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); // Very thin drop shadow
  @media (min-width: 500px) {
    width: 500px; /* Apply max-width constraint */
  }
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  // gap: 20px;

  div {
    flex: 2;
  }

  button {
    flex: 1;
  }
`;

export const OptionListPrice = styled.div`
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => (props.$full ? "#dc3545" : "inherit")};
  line-height: 1.4;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch; // This ensures children take full width
`;

export const DynamicallyChangeContent = styled.div`
  text-align: left; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  // background-color:rgba(0,0,0,0.5);
  color: black;
  // padding:2px 5px;
  width: 100%;
  height: auto;
  flex: 1;
  overflow: hidden;

  ul {
    list-style-type: disc; // Use disc for bullet points
    padding-left: 20px; // Add some padding to indent the list
    font-size: 18px;
  }
`;

export const DynamicallyChangeContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden !important; /* Add this to hide the components that are off-screen */
  width: 100%;
  background-color: rgba(255, 242, 237, 1);
  border-radius: 15px;
  padding: 10px 10px;
  // border:1px solid red;
  // min-height:100px;
  height: auto;
  position: relative;
  margin-top: 20px;
  // flex:1;
`;
export const CSSTransitionCSS = createGlobalStyle`
  .slide-right-enter {
  transform: translateX(100%);
}

.slide-right-enter-active {
  transform: translateX(0);
  transition: transform 500ms ease-in-out;
}

.slide-right-exit {

    transform: translateX(0);
}

.slide-right-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}

.slide-left-enter {
    transform: translateX(-100%);
}

.slide-left-enter-active {
  transform: translateX(0);
  transition: transform 500ms ease-in-out;
}

.slide-left-exit {
    transform: translateX(0);
}

.slide-left-exit-active {
  transform: translateX(100%);
  transition: transform 500ms ease-in-out;
}
`;

export const OptionListTitle = styled.div`
  width: 100%;
  margin: 10px auto;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
`;

const contentVariants = {
  //CHANGES by Rick: Define variants for BoxContent animations
  initial: { opacity: 0, x: -420 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 420 },
};
// BoxContent CSS (instead of importing)
const BoxContent = styled(motion.div)`
  display: block;
  width: 100%;
  border-radius: 8px;
`;

export const HeaderSection = styled.div`
  padding: 20px;
  background: white;
  border-radius: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  width:100%;
`;

export const CategoryBadge = styled.div`
  display: inline-block;
  padding: 6px 12px;
  background: #f0f7ff;
  color: #0066cc;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
`;

export const ProgramTitle = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 16px;
  line-height: 1.3;
`;

export const ProgramDate = styled.div`
  font-size: 16px;
  color: #666;
  margin-bottom: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;

  &::before {
    content: "📅";
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const LocationButton = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  background: #f8f9fa;
  border: none;
  padding: 12px 16px;
  border-radius: 12px;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #f0f0f0;
    transform: translateY(-1px);
  }
`;

export const LocationIcon = styled.div`
  width: 24px;
  height: 24px;
  color: #666;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const LocationText = styled.div`
  flex: 1;
  text-align: left;
  font-size: 16px;
  color: #333;
`;

export const MapLink = styled.span`
  color: #0066cc;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

const CourseDetailsSection = styled.div`
  margin: 24px 0;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 16px;
`;

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  position: relative;
  padding-top: 8px;
  height: auto;
`;

const StickyWrapper = styled.div`
  width: 100%;
`;

const StickySection = styled.div`
  width: 100%;
  background: white;
  padding: 16px;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  height: auto;
  overflow: visible;

  .full-booking {
    width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    padding: 12px;
    border-radius: 12px;
  }
`;

const ScrollableSection = styled.div`
  width: 100%;
  position: relative;
  background: white;
  padding: 0;
  border-radius: 16px;
  margin-top: 16px;
`;

const CourseDetailsCard = styled.div`
  width: 100%;
  min-height: 300px;
  border-radius: 16px;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // border: 1px solid red;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to bottom right,
      rgba(13, 143, 71, 0.02),
      rgba(13, 143, 71, 0.01)
    );
    border-radius: 16px;
    pointer-events: none;
  }
`;

const CourseContent = styled.div`
  color: #2c3e50;
  font-size: 15px;
  line-height: 1.6;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  height: 100%;
  width: 100%;

  .grid-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .info-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    color: #2c3e50;
    margin: 0;
    text-align: left;
    width: 100%;
  }

  p {
    margin: 0;
    color: #34495e;
    font-size: 15px !important;
    background: #f8f9fa;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid rgba(13, 143, 71, 0.1);
    text-align: left;
    width: 100%;
  }

  br {
    display: none;
  }

  .full-width {
    grid-column: 1 / -1;
  }

  @media (max-width: 768px) {
    font-size: 14px;

    .grid-container {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      gap: 12px;
    }

    h2 {
      font-size: 15px;
    }

    p {
      font-size: 14px !important;
      padding: 10px;
    }
  }
`;

const wrapContentInDivs = (htmlContent) => {
  if (!htmlContent) return "";

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");
  let wrappedContent = '<div class="grid-container">';
  let currentBlock = "";

  Array.from(doc.body.children).forEach((element, index) => {
    if (element.tagName === "H2") {
      if (currentBlock) {
        wrappedContent += `<div class="info-block">${currentBlock}</div>`;
        currentBlock = "";
      }
      currentBlock = element.outerHTML;
    } else if (element.tagName === "P" && currentBlock) {
      currentBlock += element.outerHTML;
      wrappedContent += `<div class="info-block">${currentBlock}</div>`;
      currentBlock = "";
    } else {
      if (currentBlock) {
        wrappedContent += `<div class="info-block">${currentBlock}</div>`;
        currentBlock = "";
      }
      wrappedContent += `<div class="info-block full-width">${element.outerHTML}</div>`;
    }
  });

  if (currentBlock) {
    wrappedContent += `<div class="info-block">${currentBlock}</div>`;
  }

  wrappedContent += "</div>";
  return wrappedContent;
};
