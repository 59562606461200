import React, { useState } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";

const Container = styled.div`
	padding: 10px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	pointer-events: auto;
	display: flex;
	flex-direction: column;
	gap: 6px;
	width: 100%;
	max-width: 500px;
	min-height: 50vh;
	overflow-y: auto;
`;

const Header = styled.h3`
	margin: 0 0 2px 0;
	font-size: 1.21rem;
	font-weight: 600;
`;

const CourseInfo = styled.div`
	background: #f8f9fa;
	border-radius: 8px;
	padding: 6px;
	margin-bottom: 6px;
	border: 1px solid #eee;
`;

const InfoRow = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 2px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const InfoLabel = styled.span`
	font-weight: 500;
	color: #666;
	width: 70px;
	flex-shrink: 0;
	font-size: 0.99rem;
`;

const InfoValue = styled.span`
	color: #333;
	font-weight: 500;
	font-size: 0.99rem;
`;

const QuantityContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	margin-bottom: 6px;

	span {
		font-size: 1.32rem;
		min-width: 36px;
		text-align: center;
		font-weight: 500;
	}
`;

const QuantityButton = styled.button`
	width: 44px;
	height: 44px;
	font-size: 1.65rem;
	cursor: pointer;
	border: 1px solid #e2e8f0;
	background-color: #f8fafc;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-tap-highlight-color: transparent;
	font-weight: 500;

	&:active {
		background-color: #e2e8f0;
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

const DateList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	padding-right: 4px;
	flex: 1;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 2px;
	}
`;

const DateItem = styled.li`
	margin-bottom: 2px;
	font-size: 0.99rem;
	display: flex;
	align-items: center;
	padding: 4px 3px;

	input[type="radio"] {
		width: 22px;
		height: 22px;
		margin: 0;
		flex-shrink: 0;
	}
`;

const DateLabel = styled.span`
	margin-left: 4px;
	line-height: 1.1;
`;

const Notice = styled.div`
	color: #dc2626;
	margin: 2px 0;
	font-size: 0.935rem;
	padding: 3px 4px;
	background-color: #fef2f2;
	border-radius: 4px;
	border: 1px solid #fee2e2;
`;

const Footer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 20px;
	border-top: 1px solid #ddd;
	background: white;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
`;

const PriceDisplay = styled.div`
	font-size: 18px;
	font-weight: bold;
`;

const ConfirmButton = styled.button`
	background-color: orange;
	color: white;
	padding: 10px 20px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	pointer-events: auto;
	touch-action: manipulation;
	-webkit-tap-highlight-color: transparent;

	&:active {
		background-color: darkorange;
	}
`;

const QuantityDateSelector = ({
	classDates,
	onConfirm,
	baseFee,
	maxQuantity,
}) => {
	const [quantity, setQuantity] = useState(1);
	const [selectedDates, setSelectedDates] = useState([]);

	const activeCourse = JSON.parse(Cookies.get("activeCourse") || "{}");
	const requireSelection = activeCourse?.course?.requireSelection;
	const option1value = activeCourse?.course?.option1value;
	const option2value = activeCourse?.course?.option2value;

	const handleQuantityChange = (change) => {
		setQuantity((prev) => {
			if (!maxQuantity) return prev;

			const newQuantity = Math.max(1, Math.min(prev + change, maxQuantity));
			if (selectedDates.length > newQuantity) {
				setSelectedDates(selectedDates.slice(0, newQuantity));
			}
			return newQuantity;
		});
	};

	const handleDateChange = (date) => {
		// Always replace the entire selection with the new date
		setSelectedDates([date]);
	};

	const handleConfirm = (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		// If selection is not required, confirm with just quantity
		if (!requireSelection) {
			onConfirm({
				quantity,
				totalPrice: baseFee * quantity,
				selectedClassIds: [], // CHANGED BY RICK: Added empty array for non-date-selection courses
			});
			return;
		}

		// For requireSelection, validate single date selection
		if (selectedDates.length === 1) {
			const selectedClassDate = {
				date: selectedDates[0],
				name: classDates.find((cd) => cd.date === selectedDates[0]).name,
				id: classDates.find((cd) => cd.date === selectedDates[0]).id,
			};

			onConfirm({
				quantity,
				selectedDates: [selectedClassDate],
				selectedClassIds: [selectedClassDate.id],
				totalPrice: baseFee * quantity,
			});
		}
	};

	// Sort classDates by date
	const sortedClassDates = [...classDates].sort(
		(a, b) => new Date(a.date) - new Date(b.date)
	);

	return (
		<Container onClick={(e) => e.stopPropagation()}>
			<Header>Select {requireSelection ? "Date" : "Quantity"}</Header>
			<CourseInfo>
				<InfoRow>
					<InfoLabel>Package:</InfoLabel>
					<InfoValue>{option1value}</InfoValue>
				</InfoRow>
				<InfoRow>
					<InfoLabel>Type:</InfoLabel>
					<InfoValue>{option2value}</InfoValue>
				</InfoRow>
			</CourseInfo>
			<QuantityContainer>
				<span>Quantity:</span>
				<QuantityButton
					onClick={(e) => {
						e.stopPropagation();
						handleQuantityChange(-1);
					}}
					disabled={quantity <= 1}
				>
					-
				</QuantityButton>
				<span>{quantity}</span>
				<QuantityButton
					onClick={(e) => {
						e.stopPropagation();
						handleQuantityChange(1);
					}}
					disabled={quantity >= maxQuantity}
				>
					+
				</QuantityButton>
			</QuantityContainer>
			{maxQuantity === 0 ? (
				<Notice>This course is currently full</Notice>
			) : (
				quantity === maxQuantity && (
					<Notice>Max capacity has been reached</Notice>
				)
			)}
			{requireSelection && (
				<>
					<DateList>
						{sortedClassDates.map((classDate) => (
							<DateItem key={classDate.id}>
								<input
									type="radio" // Changed to radio for single selection
									value={classDate.date}
									checked={selectedDates.includes(classDate.date)}
									onChange={(e) => {
										e.stopPropagation();
										handleDateChange(classDate.date);
									}}
								/>
								<DateLabel>
									{new Date(classDate.date).toLocaleDateString()} -{" "}
									{classDate.name}
								</DateLabel>
							</DateItem>
						))}
					</DateList>
					{selectedDates.length === 0 && <Notice>Please select a date</Notice>}
				</>
			)}
			<Footer>
				<PriceDisplay>HK$ {baseFee * quantity}</PriceDisplay>
				{!requireSelection || selectedDates.length === 1 ? (
					<ConfirmButton onClick={handleConfirm}>Book Now</ConfirmButton>
				) : (
					<ConfirmButton disabled>Select a date</ConfirmButton>
				)}
			</Footer>
		</Container>
	);
};

export default QuantityDateSelector;
